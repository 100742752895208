<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-icon left>mdi-newspaper-variant</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Album ({{ totalGalleries }})</v-toolbar-title
      >
      <v-spacer></v-spacer>

      <v-btn
        v-if="hasCreatePermission"
        color="primary"
        class="appbar__btn ml-10"
        height="40px"
        :to="{ name: 'gallery.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Create Album
      </v-btn>
    </v-app-bar>

    <div
      class="mx-4"
      id="gallery-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-tabs>
          <v-tab
            @change="
              defaultStatus = 'active'
              activeAction()
            "
          >
            Active
          </v-tab>
          <v-tab
            @change="
              defaultStatus = 'inactive'
              activeAction()
            "
          >
            Inactive
          </v-tab>
          <v-tab
            @change="
              defaultStatus = 'all'
              activeAction()
            "
          >
            All
          </v-tab>
        </v-tabs>

        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search title, building or name"
          @input="search"
        ></v-text-field>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="false"
              class="ml-10"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Bulk Actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in actions" :key="index">
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-data-table
        :headers="headers"
        :items="galleryList"
        v-model="selected"
        :options.sync="options"
        :loading="loading"
        @update:options="fetchGallery"
        :server-items-length="totalGalleries"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        @click:row="editItem"
      >
        <template v-slot:item.is_active="{ item }">
          <div style="display: flex; justify-content: center">
            <v-checkbox v-model="item.is_active" disabled readonly />
          </div>
        </template>
        <template v-slot:item.building="{ item }">
          <div v-if="item.buildingName">{{ item.buildingName }}</div>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div v-if="item.addedDate">{{ item.addedDate }}</div>
        </template>
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="!(galleryList.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Gallery list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'GalleryPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      active: true,
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultStatus: 'active',
      defaultFilter: 'created_at',
      sortOrder: false,
      headers: [
        {
          text: 'Title',
          value: 'title',
          width: '40%',
        },
        {
          text: 'Building',
          value: 'building',
          width: '10%',
        },
        {
          text: 'Active',
          value: 'is_active',
          width: '10%',
          align: 'center',
        },
        {
          text: 'Added Date',
          value: 'created_at',
          width: '15%',
        },
        {
          text: 'Created By',
          value: 'userName',
          width: '25%',
        },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
        },
      ],
      selected: [],
    }
  },

  created() {
    this.clearGallery()
    this.fetchGallery()
  },

  computed: {
    ...mapState({
      galleryList: (state) => state.gallery.list,
      listMeta: (state) => state.gallery.listMeta,
      permissions: (state) => state.auth.permissions,
      totalGalleries: (state) => state.gallery.totalGalleries,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },
    statusFilter() {
      return [
        {
          text: 'All',
          value: null,
        },
        {
          text: 'Active',
          value: 'active',
        },
        {
          text: 'Blocked',
          value: 'blocked',
        },
        {
          text: 'Deleted',
          value: 'deleted',
        },
      ]
    },

    hasCreatePermission() {
      return validatePermissions(
        [PERMISSION.GALLERIES_CREATE],
        this.permissions
      )
    },
  },

  methods: {
    ...mapActions({
      getGallery: 'gallery/getGallery',
    }),

    ...mapMutations({
      clearGallery: 'gallery/clearGalleryList',
    }),

    filterList(filterValue) {
      this.clearGallery()
      this.fetchGallery(null, filterValue)
    },

    async exportList() {
      this.loading = true

      await this.exportToExcel({
        sort: this.getSortParam(),
      })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'users_list.xlsx')
          document.body.appendChild(link)
          link.click()
          this.showSnackbar('File successfully downloaded')
        })
        .catch((err) => {
          this.showSnackbar(
            this.getErrorMessage(err, 'Oops! Something went wrong'),
            false
          )
        })
        .finally(() => {
          this.loading = false
        })
    },

    activeAction() {
      this.clearGallery()
      this.fetchGallery()
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetchGallery(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearGallery()
      this.fetchGallery()
    }, 600),

    async fetchGallery(options, page = 1) {
      if (this.loading) return
      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalGalleries
            : options.itemsPerPage
          : 25,
        filter: this.defaultStatus,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : 'title',
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.getGallery(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
    editItem(item) {
      this.$router.push({
        name: 'gallery.details',
        params: { id: item.id },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-input.v-input--checkbox {
  justify-content: center;
  margin: 0;

  .v-input__control {
    width: auto;
    flex-grow: 0;
  }

  .v-input__slot {
    .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}

.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}
.v-list-item__title {
  cursor: pointer;
}

.v-input.v-input--checkbox {
  justify-content: left !important;
}
</style>
